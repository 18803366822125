import { URL_GET_RECURSOH } from "../router/Url";


export const GetResourceOT = (data,planta) => {
  // console.log(data);
  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_GET_RECURSOH+data+'&planta='+planta,{
      method: 'GET',
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    let responsito = await query.json();
    // console.log(responsito);

    // if(responsito.data.success){
      resolve(responsito)
    // }else{
    //   resolve(responsito.data)
    // }
  })
}
