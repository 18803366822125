import { URL_UPLOAD_FILE } from "../router/Url";

export const UploadFile = async ({taskID ,file, planta}) => {
  // console.log(taskID ,file)

  return new Promise(async (resolve, reject) => {

    const formdata = new FormData();
    formdata.append("taskID",taskID)
    formdata.append("file",file)
    formdata.append("planta",planta)

    let query = await fetch(URL_UPLOAD_FILE,{
      method: 'POST',
      headers: {
        // 'authorization': "marcuxo",
        'Accept': '*/*',
        // 'Content-Type': 'application/json'
      },
      body: formdata
    })
    let responsito = await query.json();
    // console.log(responsito);
    if(responsito.success){
      resolve(responsito.url)
    }else{
      resolve('')
    }
  })
 
}
