import { URL_SRV_RAIZ } from "../router/Url";


export const GetUserFracttal = async (data) => {
  console.log(data)

  return new Promise(async (resolve, reject) => {
    let query = await fetch(URL_SRV_RAIZ+'getuser_fracttal_planta',{//'getuser_fracttal',{
      method: "POST",
      headers: {
        'authorization': "marcuxo",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        PLANTA: data.toUpperCase()
      })
    })

    let responsito = await query.json();
    if(responsito.success){
      // console.log(responsito.item);
      resolve(responsito.item)
    }else{
      resolve(responsito.success)
    }
  })
 
}
