import { URL_UPDATE_SUB_TASKS } from "../router/Url";


export const UpdateSubTask = async (data,planta) => {
  // console.log(data)

  let arr_temp = []
  for (let dt = 0; dt < data.length; dt++) {
    const dt_ = data[dt];
    if(dt_.value !== ""){
      arr_temp.push({"id":String(dt_.id_work_orders_tasks_form_items),"value":dt_.value});
    }
  }
  // console.log(arr_temp);
  for (let udtsk = 0; udtsk < arr_temp.length; udtsk++) {
    const udtsk_ = arr_temp[udtsk];
    // console.log(udtsk_);
    new Promise(async (resolve, reject) => {
      let query = await fetch(URL_UPDATE_SUB_TASKS,{
        method: 'PUT',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id:udtsk_.id,value:udtsk_.value,planta:planta
        })
      })
      let responsito = await query.json();
      // console.log(responsito);
      if(responsito.data.success){
        resolve(responsito.data)
      }else{
        resolve(responsito.data)
      }
    })
  }
  return
 
}
