import { Button, TextField } from '@mui/material';
import React, { Fragment, useContext, useEffect, useState } from 'react'
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import { HeaderMenu } from '../../component/HeaderMenu';
import { useLocation } from 'react-router-dom';
import { GetSubTasks } from '../../api/GetSubTasks.api';//ok

/** ICONS */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ModalLoaded } from '../../component/ModalLoaded.component';
import LoginContext from '../../context/login_context/LoginContext';

const options = {

   // default is `save`
   method: 'open',//download
   // default is Resolution.MEDIUM = 3, which should be enough, higher values
   // increases the image quality but also the size of the PDF, so be careful
   // using values higher than 10 when having multiple pages generated, it
   // might cause the page to crash or hang.
   resolution: Resolution.MEDIUM,
   page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: 'letter',
      // default is 'portrait','landscape'
      orientation: 'portrait',
   },
   canvas: {
      // default is 'image/jpeg' for better size performance
      mimeType: 'image/png',
      qualityRatio: 1
   },
   // Customize any value passed to the jsPDF instance and html2canvas
   // function. You probably will not need this and things can break, 
   // so use with caution.
   overrides: {
      // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
      pdf: {
         compress: true
      },
      // see https://html2canvas.hertzen.com/configuration for more options
      canvas: {
         useCORS: true
      }
   },
};

// you can use a function to return the target element besides using React refs
const getTargetElement = () => document.getElementById('content-id');

export const SiNo_ = ({value}) => {
  // console.log(value);
  switch (value) {
    case 'true':
      return <span><CheckBoxIcon/>Si<CheckBoxOutlineBlankIcon/>No<CheckBoxOutlineBlankIcon/>N/A</span>
    case 'false':
      return <span><CheckBoxOutlineBlankIcon/>Si<CheckBoxIcon/>No<CheckBoxOutlineBlankIcon/>N/A</span>
    case 'N/A':
      return <span><CheckBoxOutlineBlankIcon/>Si<CheckBoxOutlineBlankIcon/>No<CheckBoxIcon/>N/A</span>
  }
}

export const AproboAlertaFallo_ = ({value}) => {
  // console.log(value);
  switch (value) {
    case '1':
      return <span><CheckBoxIcon/>Aprobó<CheckBoxOutlineBlankIcon/>Alerta<CheckBoxOutlineBlankIcon/>Falló</span>
    case '2':
      return <span><CheckBoxOutlineBlankIcon/>Aprobó<CheckBoxIcon/>Alerta<CheckBoxOutlineBlankIcon/>Falló</span>
    case '3':
      return <span><CheckBoxOutlineBlankIcon/>Aprobó<CheckBoxOutlineBlankIcon/>Alerta<CheckBoxIcon/>Falló</span>
  }
}

export const OtPdf = () => {
  const {LoginState} = useContext(LoginContext);
  const [ModalLoadded, setModalLoadded] = useState(false);
	const [Texto, setTexto] = useState('');

  const [SubTaskBault, setSubTaskBault] = useState([])
  const {state} = useLocation()

  // console.log(state.TASK);
  const ObtainSubTasks = async () => {
    setTexto('Estamos Obteniendo la informacion de la OT')
		await setModalLoadded(true)
    let bault = []
    for (let tsk = 0; tsk < state.TASK.length; tsk++) {
      const tsk_ = state.TASK[tsk];
      let SubTaskData = await GetSubTasks({subtask_id:tsk_.id_work_orders_tasks,planta:LoginState.planta})
      bault.push(SubTaskData);
    }
    // console.log(bault);
    setSubTaskBault(bault)
    await setModalLoadded(false)
  }
  useEffect(() => {
    ObtainSubTasks()
    
  }, [])
  
  const [NumeroOT, setNumeroOT] = useState('')
  return (
    <>
    <HeaderMenu Pag_Sel={'ot'} />
      <div >
        <div className='row' style={{width:900, paddingLeft:15, paddingRight:15, marginLeft: 'auto', marginRight: 'auto', marginTop: 15}} >
          <div className='col-9'>
            {/* <TextField id="outlined-basic" size='small'
              value={NumeroOT}
              onChange={(e)=>setNumeroOT(e.target.value.toLocaleUpperCase())}
              label="Numero de OT"
              variant="outlined" /><span className='ml-3'/>
            <Button variant="contained">Generar PDf</Button> */}
          </div>
          <div className='col-3 text-right'>
            <button className='btn btn-success' onClick={() => generatePDF(getTargetElement, options)}>Ver en PDF</button>
          </div>
        </div>
      </div>
      <div style={{width:900, paddingLeft:15, paddingRight:15, marginLeft: 'auto', marginRight: 'auto'}} id="content-id">
        <div className='row'>
          <div className='col-12'>
            <div className='row'>
              <div className='col-3'>
                <img src={require('../../assets/logoariztia.png')} />
              </div>
              <div className='col-6 pt-3 text-center'>
                <span><b>EMPRESA ARIZTIA</b></span><br/><br/>
                <span className='mt-3'>Orden de Trabajo</span>
              </div>
              <div className='col-3 pt-3'>
                <span><b>N°: {state.OT.wo_folio}</b></span><br/>
                <span>Fecha: <b className='text-danger'>{new Date().toISOString().split('T')[0]}</b></span><br/>
                <span>Calificacion:</span>
              </div>
            </div>
          </div>
          {/*  */}
          <div className='col-12 pdf-borde'>
            <div className='row'>
              <div className='col-6'>
                <span><b>GENERÓ:</b> {state.OT.created_by}</span><br/>
                <span><b>DURACIÓN ESTIMADA:</b> {
																	state.OT.duration/60>59?
																	(Math.floor(Math.trunc((state.OT.duration/60)/60))>=10?
																	Math.floor(Math.trunc((state.OT.duration/60)/60))
																	:"0"+Math.floor(Math.trunc((state.OT.duration/60)/60)))+":"+((state.OT.duration/60)-(60*(Math.floor(Math.trunc((state.OT.duration/60)/60)))))
																	:"00:"+state.OT.duration/60
																}</span>
              </div>
              <div className='col-6'>
                <span><b>RESPONSABLE:</b> {state.OT.user_assigned}</span><br/>
                <span><b>NOTAS:</b> {state.OT.note}</span>
              </div>
            </div>
          </div>

          {/*  */}
          <div className='col-12 pdf-borde mt-3'>
          </div>

          {/*  */}
          <div className='col-12 mt-2'>
            <div className='row'>
              <div className='col-12'>
              <h5><u>ACTIVOS</u></h5>
              </div>
              <div className='col-6'>
                <span><b>DESCRIPCION:</b> {state.OT.items_log_description}</span><br/>
                <span><b>UBICADO EN O ES PARTE DE:</b> {state.OT.parent_description}</span><br/>
                <span><b>TIPO:</b> {state.OT.groups_description}</span><br/>
                <span><b>PRIORIDAD:</b> {state.OT.priorities_description}</span><br/>
              </div>
              <div className='col-6'>
                <span><b>CLASIFICACION 1:</b> {state.OT.groups_1_description}</span><br/>
                <span><b>CLASIFICACION 2:</b> {state.OT.groups_2_description}</span><br/>
                <span><b>CENTRO DE COSTO:</b> {state.OT.costs_center_description}</span><br/>
              </div>
            </div>
          </div>

          {
            state.TASK.map((task,index)=><Fragment key={task.id_work_orders_tasks}>
              {/*  */}
              <div className='col-12 mt-3'>
                <h5><u>TAREA NO PLANIFICADA</u></h5>
              </div>
              <div className='col-12 pdf-borde'>
                <div className='row'>
                  <div className='col-12'>
                  </div>
                  <div className='col-6'>
                    <span><b>DESCRIPCION:</b> {task.description}</span><br/>
                    <span><b>FECHA PROGRAMADA:</b> {task.cal_date_maintenance.split('T')[0]}</span><br/>
                    <span><b>TIPO DE MANTENIMIENTO:</b>{task.tasks_log_task_type_main}</span><br/>
                    {/* <span><b>PRIORIDAD:</b> {task.groups_1_description}</span><br/> */}
                    {/* <span><b>ACTIVADOR:</b> {task.groups_1_description}</span><br/> */}
                    <span><b>FECHA DEL EVENTO:</b> {new Date(task.event_date).toLocaleString()}</span><br/>
                  </div>
                  <div className='col-6'>
                    <span><b>CLASIFICACION 1:</b> {task.tasks_log_types_description}</span><br/>
                    <span><b>CLASIFICACION 2:</b> {task.tasks_log_types_2_description}</span><br/>
                    <span><b>SOLICITADA POR:</b> {task.requested_by}</span><br/>
                    <span><b>NOTAS:</b> {task.task_note}</span><br/>
                  </div>
                </div>
              </div>
              
              {
                task?.id_request!==null?<Fragment>
                  <div className='col-12 mt-3'>
                    <h5><u>INFORMACION DE LA SOLICITUD</u></h5>
                  </div>
                  <div className='col-12'>
                    <div className='row'>
                      <div className='col-4 pdf-borde-c text-center'>
                        <span><b>Numero De Solicitud</b></span>
                      </div>
                      <div className='col-8 pdf-borde-c'>
                        <span><b>Solicitado Por</b></span>
                      </div>
                      <div className='col-4 pdf-borde-c'>
                        <span>{task?.id_request}</span>
                      </div>
                      <div className='col-8 pdf-borde-c'>
                        <span>{task?.request_by}</span>
                      </div>
                    </div>
                  </div>
                </Fragment>:null
              }
              

              {/*  */}
              <div className='col-12 mt-3'>
                <div className='row'>
                  <div className='col-12 pdf-borde-c text-center'>
                    <h3>SUBTAREAS</h3>
                  </div>
                  <div className='col-4 pdf-borde-c'><span><b>Grupo/Parte</b></span></div>
                  <div className='col-4 pdf-borde-c'><span><b>Procedimiento</b></span></div>
                  <div className='col-4 pdf-borde-c'><span><b>Resultado</b></span></div>
                {SubTaskBault.length>0?SubTaskBault[index].map(stfr=>
                  <Fragment key={stfr.id_work_orders_tasks_form_items}>
                    <div className='col-4 pdf-borde-c'><small>{stfr.group}</small></div>
                    <div className='col-4 pdf-borde-c'><small>{stfr.description}</small></div>
                    <div className='col-4 pdf-borde-c' style={{display: 'flex', alignContent: 'center', alignItems: 'center'}}>
                      <small>{
                        stfr.id_task_form_item_type===1?stfr.value:
                        stfr.id_task_form_item_type===3?stfr.value:
                        stfr.id_task_form_item_type===2?<SiNo_ value={stfr.value}/>:
                        stfr.id_task_form_item_type===4?<AproboAlertaFallo_ value={stfr.value}/>:null
                      }</small></div>
                  </Fragment>
                ): null}
                </div>
              </div>
            </Fragment>)
          }

        </div>
      </div>
      {
				 ModalLoadded?<ModalLoaded texto={Texto} />:null
			}
      {/* BORRAR */}
      <br/>
      <br/>
      <br/>
      <br/>
    </>
  )
}
